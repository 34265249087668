/**
 * Module dependencies.
 */

import { NavbarProps } from 'src/types/navbar';
import { RouteName } from 'i18n-routes';
import { color, media, units } from 'src/styles/utils';
import { getHrefLangs } from 'src/core/utils/href-langs';
import { getModuleTheme } from 'src/core/utils/colors';
import { prop, switchProp, theme } from 'styled-tools';
import Loading from 'src/components/core/loading';
import Metatags, { HrefLang } from 'src/components/metatags';
import ModulesBuilder, {
  Module,
  ModuleName,
  modulesName
} from 'src/components/modules/modules-builder';

import React, { ReactElement, useMemo } from 'react';
import compact from 'lodash/compact';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';
import useBreakpoint from 'src/hooks/use-breakpoint';
import useNavbarTheme from 'src/context/navbar-theme/use-navbar-theme';
import usePage from 'src/api/app/pages/use-page';

/**
 * First module whitelist.
 */

const firstModuleWhitelist = [
  modulesName.ambients,
  modulesName.awards,
  modulesName.catalogsSlider,
  modulesName.collectionsSlider,
  modulesName.contacts,
  modulesName.downloads,
  modulesName.highlightThreeImages,
  modulesName.highlightTwoImages,
  modulesName.iframe,
  modulesName.imageSlider,
  modulesName.magazinesSlider,
  modulesName.showrooms,
  modulesName.textAndImage,
  modulesName.textAndImageWithSubtitle,
  modulesName.tools
];

/**
 * `EmptyModuleType` type.
 */

type EmptyModuleType = {
  module: ModuleName,
  moduleColor: string | null
};

/**
 * Export `PageProps` type.
 */

export type PageProps = {
  descriptionSeo?: string | null,
  id: string,
  modules: Module[],
  slug: string,
  title: string,
  titleSeo?: string | null,
  translations: Record<string, unknown>[]
};

/**
 * `Props` type.
 */

type Props = {
  data: PageProps;
  hrefLangs?: HrefLang[];
  routeName: RouteName;
  slug: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  min-height: 100vh;
  position: relative;
`;

/**
 * `EmptyModule` styled component.
 */

const EmptyModule = styled.div<EmptyModuleType>`
  ${switchProp('module', {
    [modulesName.ambients]: css`
      ${media.max('ms')`
        background-color: ${prop('moduleColor')};
        height: ${theme('dimensions.navbarHeightMobile')}px;
      `}
    `,
    [modulesName.awards]: css`
      ${media.max('ms')`
        background-color: ${prop('moduleColor')};
        height: ${theme('dimensions.navbarHeightMobile')}px;
      `}
    `,
    [modulesName.catalogsSlider]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.collectionsSlider]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.contacts]: css`
      ${media.max('ms')`
        height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});
      `}
    `,
    [modulesName.downloads]: css`
      ${media.max('ms')`
        background-color: ${color('grey200')};
        height: ${theme('dimensions.navbarHeightMobile')}px;
      `}
    `,
    [modulesName.highlightThreeImages]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.highlightTwoImages]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.iframe]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.imageSlider]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.magazinesSlider]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.showrooms]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `,
    [modulesName.textAndImage]: css`
      ${media.max('ms')`
        background-color: ${prop('moduleColor')};
        height: ${theme('dimensions.navbarHeightMobile')}px;
      `}
    `,
    [modulesName.textAndImageWithSubtitle]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px);

      ${media.min('ms')`
        background-color: ${prop('moduleColor')};
        height: calc(${theme('dimensions.navbarHeight')}px);
      `}
    `,
    [modulesName.tools]: css`
      height: calc(${theme('dimensions.navbarHeightMobile')}px + ${units(6)});

      ${media.min('ms')`
        height: calc(${theme('dimensions.navbarHeight')}px + ${units(6)});
      `}
    `
  })}
`;

/**
 * Get first module.
 */

function getFirstModule(modules: Module[]): EmptyModuleType | null {
  if (isEmpty(modules)) {
    return null;
  }

  const validModules = filter(compact(modules), ({ module }) => {
    return Object.values(modulesName).includes(module);
  });

  const { module, moduleColor } = validModules[0];

  return { module, moduleColor };
}

/**
 * Get navbar theme.
 */

function getNavbarTheme(module: ModuleName, moduleColor: string | null, isMobile: boolean): NavbarProps {
  const moduleColorTheme = getModuleTheme(moduleColor);

  switch (module) {
    case modulesName.ambients:
    case modulesName.awards:
    case modulesName.textAndImage:
      return {
        colorTheme: moduleColorTheme
      };

    case modulesName.contacts:
    case modulesName.textAndImageWithSubtitle:
      return {
        colorTheme: isMobile ? 'dark' : moduleColorTheme
      };

    case modulesName.banner:
    case modulesName.highlightOneImages:
      return {
        colorTheme: 'light',
        hasShadow: true
      };

    default:
      return {
        colorTheme: 'dark'
      };
  }
}

/**
 * `PageBuilder` page.
 */

const PageBuilder = (props: Props): ReactElement => {
  const { data: pageData, hrefLangs: pageHrefLangs, routeName, slug } = props;
  const isMobile = useBreakpoint('md', 'max');
  const {
    data,
    isLoading,
    isSuccess
  } = usePage<PageProps>({ initialData: pageData, slug });

  const { firstModule, navbarTheme } = useMemo(() => {
    const firstModule = getFirstModule(data?.modules);
    const theme = getNavbarTheme(
      firstModule?.module,
      firstModule?.moduleColor,
      isMobile
    );

    return {
      firstModule,
      navbarTheme: firstModule ? theme : 'dark'
    };
  }, [data, isMobile]);

  const hrefLangs = useMemo(() => 
    pageHrefLangs || getHrefLangs(data?.translations, routeName), 
    [data?.translations, pageHrefLangs, routeName]
  );

  // @ts-ignore
  useNavbarTheme({ theme: navbarTheme });

  return (
    <>
      <Metatags
        description={data?.descriptionSeo}
        hrefLangs={hrefLangs}
        pageTitle={data?.title}
        title={data?.titleSeo}
      />

      <Wrapper>
        <Loading active={isLoading} />

        {isSuccess && (
          <>
            {firstModule && includes(firstModuleWhitelist, firstModule?.module) && (
              <EmptyModule {...firstModule} />
            )}

            <ModulesBuilder modules={data?.modules} />
          </>
        )}
      </Wrapper>
    </>
  );
};

/**
 * Export `PageBuilder` page.
 */

export default PageBuilder;
