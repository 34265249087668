
/**
 * Module dependencies.
 */

import { CatalogProps } from 'src/types/catalogs';
import { assetUrlResolve } from 'src/core/utils/url-resolver';
import { color, units } from 'src/styles/utils';
import { switchProp, theme } from 'styled-tools';
import CardTitle from 'src/components/core/typography/card-title';
import IconButton from 'src/components/core/buttons/icon-button';
import Image, { ImageProps } from 'src/components/core/image';
import React, { ReactElement } from 'react';
import downloadIcon from 'src/assets/svg/download.svg';
import styled, { css } from 'styled-components';

/**
 * `BgColorTheme` type.
 */

type BgColorTheme = 'white' | 'grey';

/**
 * `Props` type.
 */

type Props = CatalogProps & {
  bgColorTheme?: BgColorTheme
};

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)<ImageProps>`
  transition: transform 5s ease-in;
`;

/**
 * `Card` styled component.
 */

const Card = styled.div<{ bgColorTheme: BgColorTheme }>`
  background-color: ${color('beige100')};
  color: ${color('textColor')};
  display: grid;
  grid-template-areas:
    'image image image'
    '.     info  .';
  grid-template-columns: ${units(3)} 1fr ${units(3)};
  grid-template-rows: ${units(44)} ${units(20)};
  padding-bottom: ${units(3)};
  transition: color ${theme('animations.defaultTransition')};

  ${switchProp('bgColorTheme', {
    grey: css`
      background-color: ${color('grey100')};
    `,
    white: css`
      background-color: ${color('white')};
    `
  })}

  &:focus,
  &:hover {
    color: ${color('brown400')};

    ${StyledImage} {
      transform: scale(1.5);
    }
  }
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  grid-area: image;
  height: ${units(44)};
  overflow: hidden;
  position: relative;
`;

/**
 * `InfoWrapper` styled component.
 */

const InfoWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-area: info;
  justify-content: space-between;
  overflow: hidden;
  padding-top: ${units(3)};
`;

/**
 * `CatalogCard` component.
 */

const CatalogCard = (props: Props): ReactElement => {
  const { bgColorTheme, downloadUrl, imageUrl, name } = props;

  return (
    <Card bgColorTheme={bgColorTheme}>
      <ImageWrapper>
        <StyledImage
          alt={name}
          layout={'fill'}
          objectFit={'cover'}
          src={imageUrl ?? assetUrlResolve('/static/images/empty/list@2x.jpg')}
        />
      </ImageWrapper>

      <InfoWrapper>
        <CardTitle size={'small'}>
          {name}
        </CardTitle>

        <IconButton
          aria-label={'Download'}
          icon={downloadIcon}
          iconSize={units(4)}
          onClick={() => window.open(downloadUrl, '_blank')}
        />
      </InfoWrapper>
    </Card>
  );
};

/**
 * Default props.
 */

CatalogCard.defaultProps = {
  bgColorTheme: 'beige100'
};

/**
 * Export `CatalogCard` component.
 */

export default CatalogCard;
