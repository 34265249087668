
/**
 * Module depenencies.
 */

import { CatalogProps } from 'src/types/catalogs';
import { CollectionProps } from 'src/types/collection';
import { MagazineProps } from 'src/types/magazine';
import { breakpoints } from 'src/styles/breakpoints';
import { color, media, units } from 'src/styles/utils';
import { isExternalRoute } from 'src/core/utils/routes';
import { theme } from 'styled-tools';
import { useRouter } from 'next/router';
import Box from 'src/components/core/layout/box';
import CatalogCard from 'src/components/cards/catalog-card';
import CollectionCard from 'src/components/cards/collection-card';
import Container from 'src/components/core/layout/container';
import GalleryNavigation from 'src/components/core/slider/gallery-navigation';
import IconButton from 'src/components/core/buttons/icon-button';
import MagazineCard from 'src/components/cards/magazine-card';
import ParallaxFadeInUp from 'src/components/core/animations/parallax-fade-in-up';
import React, { ReactElement, useCallback, useRef } from 'react';
import Richtext from 'src/components/core/richtext';
import Slider, { Slide } from 'src/components/core/slider';
import Type from 'src/components/core/typography';
import arrowRightIcon from 'src/assets/svg/arrow-right.svg';
import map from 'lodash/map';
import size from 'lodash/size';
import styled from 'styled-components';

/**
 * `BaseProps` type.
 */

type BaseProps = {
  className?: string,
  description: string,
  isKiosk?: boolean,
  title: string,
  url?: string
};

/**
 * `Props` type.
 */

type Props = BaseProps & {
  items: CollectionProps[],
  type: 'collections'
} | BaseProps & {
  items: MagazineProps[],
  type: 'magazines'
} | BaseProps & {
  items: CatalogProps[],
  type: 'catalogs'
};

/**
 * `Section` component.
 */

const Section = styled.section`
  background-color: ${color('white')};
  padding: ${units(13)} 0;
  position: relative;

  ${media.min('ms')`
    padding: ${units(25)} 0;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  padding: 0;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-template-areas:
    '. title     .'
    '. gallery   gallery'
    '. scrollbar .';
  grid-template-columns: ${theme('grid.gutterMobile')}px 1fr ${theme('grid.gutterMobile')}px;

  ${media.min('ms')`
    grid-template-areas:
      '. . title      .          .       .'
      '. . gallery    gallery    gallery gallery'
      '. . navigation navigation .       .'
      '. . scrollbar  scrollbar  .       .';
    grid-template-columns: ${theme('grid.gutter')}px 1fr 4fr 6fr 1fr ${theme('grid.gutter')}px;
  `}
`;

/**
 * `Gallery` styled component.
 */

const Gallery = styled.div`
  grid-area: gallery;
  margin-bottom: ${units(6)};
  width: 100%;

  ${media.min('ms')`
    margin-bottom: 0;
  `}
`;

/**
 * `TitleWrapper` styled component.
 */

const TitleWrapper = styled.div`
  grid-area: title;
  margin-bottom: ${units(6)};
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H3)`
  margin-bottom: ${units(3)};
`;

/**
 * `GalleryNavigationWrapper` styled component.
 */

const GalleryNavigationWrapper = styled.div`
  grid-area: navigation;
`;

/**
 * `StyledGalleryNavigation` styled component.
 */

const StyledGalleryNavigation = styled(GalleryNavigation)`
  display: none;

  ${media.min('ms')`
    display: flex;
    margin-bottom: ${units(1)};
    padding-top: ${units(3)};
  `}
`;

/**
 * `ScrollbarWrapper` styled component.
 */

const ScrollbarWrapper = styled.div`
  grid-area: scrollbar;
`;

/**
 * `Scrollbar` styled component.
 */

const Scrollbar = styled.div`
  padding: ${units(2.5)} 0;

  .gallery-scrollbar {
    background-color: ${color('grey200')};
    height: 1px;
  }

  .gallery-scrollbar-drag {
    background: ${color('brown400')};
    height: 1px;
  }
`;

/**
 * Get carousel config.
 */

function getCarouselConfig() {
  return {
    breakpoints: {
      [breakpoints.sm]: {
        slidesPerView: 1.35,
        spaceBetween: 20
      },
      [breakpoints.ms]: {
        slidesPerView: 2.25,
        spaceBetween: 30
      },
      [breakpoints.md]: {
        slidesPerView: 2.75,
        spaceBetween: 30
      },
      [breakpoints.lg]: {
        slidesPerView: 3.25,
        spaceBetween: 40
      },
      [breakpoints.xl]: {
        slidesPerView: 3.50,
        spaceBetween: 40
      },
      1600: {
        slidesPerView: 4.50,
        spaceBetween: 40
      }
    },
    slidesPerView: 1.35,
    spaceBetween: 20
  };
}

/**
 * `SliderSection` component.
 */

const SliderSection = (props: Props): ReactElement | null => {
  const { className, description, isKiosk, items, title, type, url } = props;
  const { locale } = useRouter();
  const total = size(items);
  const sliderRef = useRef<any>();
  const handlePrevious = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slidePrev();
    }
  }, []);

  const handleNext = useCallback(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.swiper.slideNext();
    }
  }, []);

  if (total === 0) {
    return null;
  }

  return (
    <Section className={className}>
      <StyledContainer
        fluid
        hasPadding={!isKiosk}
      >
        <Grid>
          <TitleWrapper>
            <Title>
              <ParallaxFadeInUp parallaxOptions={{ speed: 1 }}>
                {title}
              </ParallaxFadeInUp>
            </Title>

            <ParallaxFadeInUp parallaxOptions={{ speed: 2 }}>
              <Richtext>
                {description}
              </Richtext>

              {url && (
                <Box paddingTop={units(2)}>
                  <IconButton
                    aria-label={title}
                    {...!isExternalRoute(url) ? { locale } : {
                      rel: 'noopener',
                      target: '_blank'
                    }}
                    href={url}
                    icon={arrowRightIcon}
                    iconSize={units(4)}
                  />
                </Box>
              )}
            </ParallaxFadeInUp>
          </TitleWrapper>

          <Gallery>
            <ParallaxFadeInUp
              inViewOptions={{ threshold: 0.1 }}
              parallaxOptions={{ speed: 3 }}
            >
              <Slider
                {...getCarouselConfig()}
                ref={sliderRef}
                scrollbar={{
                  dragClass: 'gallery-scrollbar-drag',
                  el: '.gallery-scrollbar',
                  hide: false
                }}
              >
                {map(items, (item, index: number) => (
                  <Slide key={index}>
                    {type === 'collections' && (
                      <CollectionCard
                        {...item}
                        bgColorTheme={'grey'}
                        isKioskCard={isKiosk}
                      />
                    )}

                    {type === 'magazines' && (
                      <MagazineCard
                        {...item}
                        bgColorTheme={'grey'}
                      />
                    )}

                    {type === 'catalogs' && (
                      <CatalogCard
                        {...item}
                        bgColorTheme={'grey'}
                      />
                    )}
                  </Slide>
                ))}
              </Slider>
            </ParallaxFadeInUp>
          </Gallery>

          {total > 1 && (
            <GalleryNavigationWrapper>
              <ParallaxFadeInUp parallaxOptions={{ speed: 3 }}>
                <StyledGalleryNavigation
                  onGoToNext={handleNext}
                  onGoToPrevious={handlePrevious}
                  total={total}
                />
              </ParallaxFadeInUp>
            </GalleryNavigationWrapper>
          )}

          <ScrollbarWrapper>
            <ParallaxFadeInUp parallaxOptions={{ speed: 3 }}>
              <Scrollbar>
                <div className={'gallery-scrollbar'} />
              </Scrollbar>
            </ParallaxFadeInUp>
          </ScrollbarWrapper>
        </Grid>
      </StyledContainer>
    </Section>
  );
};

/**
 * Export `SliderSection` component.
 */

export default SliderSection;
