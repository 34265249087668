
/**
 * Module dependencies.
 */

import { assetUrlResolve } from 'src/core/utils/url-resolver';
import Head from 'next/head';
import React, { ReactElement, useMemo } from 'react';
import map from 'lodash/map';
import useConfigurations, { 
  ConfigurationsData
} from 'src/api/app/configurations/use-configurations';

import useContacts from 'src/api/app/contacts/use-contacts';

/**
 * Base URL.
 */

const baseUrl: string = process.env.NEXT_PUBLIC_BASE_URL;

/**
 * Default name.
 */

const defaultName = 'Revigrés';

/**
 * `Props` type.
 */

type Props = {
  data: ConfigurationsData
  description?: string
};

/**
 * `SchemaHomepage` component.
 */

const SchemaHomepage = (props: Props): ReactElement => {
  const { description } = props;
  const { data: configurationsData } = useConfigurations({
    initialData: props?.data
  });

  const data = configurationsData ?? props?.data;
  const { data: contacts } = useContacts();
  const localsBusiness = useMemo(() => {
    return map(contacts, ({ address, city, country, phone, postalCode }) => {
      return {
        '@type': 'PostalAddress',
        addressCountry: country?.alpha2,
        addressLocality: city,
        postalCode,
        streetAddress: address,
        telephone: phone
      };
    });
  }, [contacts]);

  const schemaLocalBusiness = {
    '@context': 'http://schema.org',
    '@type': 'LocalBusiness',
    address: localsBusiness,
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingCount: data?.ratingCount,
      ratingValue: data?.ratingValue
    },
    description,
    name: defaultName,
    openingHours: data?.openingHours?.split(/\r?\n/),
    url: baseUrl
  };

  const schemaOrganization = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingCount: data?.ratingCount,
      ratingValue: data?.ratingValue
    },
    description,
    logo: assetUrlResolve(`${baseUrl}/static/images/logo.png`),
    name: defaultName,
    sameAs: [
      data?.facebook,
      data?.instagram,
      data?.linkedin,
      data?.youtube,
      data?.issuu,
      data?.wikipedia
    ],
    url: baseUrl
  };

  if(!data) {
    return <></>;
  }

  return (
    <Head>
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line id-match
          __html: JSON.stringify(schemaOrganization) 
        }}
        id={'schema-organization'}
        type={'application/ld+json'}
      />

      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          // eslint-disable-next-line id-match
          __html: JSON.stringify(schemaLocalBusiness)
        }}
        id={'schema-local-business'}
        type={'application/ld+json'}
      />
    </Head>
  );
};

/**
 * Export `SchemaHomepage` component.
 */

export default SchemaHomepage;
