
/**
 * Module dependencies.
 */

import { ConfigurationsData } from 'src/api/app/configurations/use-configurations';
import { fetchConfigurations } from 'src/api/app/configurations/fetch-configurations';
import { fetchPage } from 'src/api/app/pages/fetch-page';
import { getApiPageSlugByLocale } from 'src/core/utils/routes';
import { routes } from 'src/core/routes';
import PageBuilder, { PageProps } from 'src/components/page-builder/index';
import React, { ReactElement } from 'react';
import SchemaHomepage from 'src/components/schema-homepage';
import isEmpty from 'lodash/isEmpty';

/**
 * `Props` type.
 */

type Props = {
  data: PageProps,
  schemaData: ConfigurationsData,
  slug: string
};

/**
 * `Home` page.
 */

const Home = (props: Props): ReactElement => (
  <>
    <SchemaHomepage
      data={props?.schemaData}
      description={props?.data?.descriptionSeo}
    />

    <PageBuilder
      {...props}
      routeName={'home'}
    />
  </>
);

/**
 * Export `getServerSideProps`.
 */

export async function getServerSideProps({ locale, query }) {
  const slug = getApiPageSlugByLocale('home', locale);
  const { preview: hasPreview, ...queryRest } = query;

  if (!isEmpty(queryRest)) {
    return {
      redirect: {
        destination: routes[locale].home,
        permanent: false
      }
    };
  }

  const pageData = await fetchPage({
    hasPreview,
    locale,
    slug
  });

  const schemaData = await fetchConfigurations()

  if (!pageData || !schemaData) {
    return {
      notFound: true
    };
  }

  return {
    props: {
      data: pageData,
      schemaData,
      slug
    } as Props
  };
}

/**
 * Export `Home` page.
 */

export default Home;
